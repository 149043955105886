<template>
    <div class="d-flex flex-row flex-wrap">
        <div class="w-100">
            <Header title="" />
        </div>
        <left-side-bar />
        <div class="d-flex flex-row flex-wrap align-items-center w-75 pl-3">
            <div
                class="
                    d-flex
                    flex-row flex-wrap
                    justify-between
                    align-items-center
                    w-100
                "
            >
                <h1 class="font-xx-large font-weight-bold">Transporte</h1>
            </div>
            <el-tabs
                @tab-click="onTabChanged"
                v-model="activeName"
                class="w-100"
            >
                <el-tab-pane label="Rutas" name="first"></el-tab-pane>
            </el-tabs>
            <h1 class="font-x-large font-weight-bold">Rutas</h1>
            <nav class="d-flex flex-row align-items-center text-gray-dark ml-3">
                <router-link
                    to="routes"
                    class="d-flex flex-row align-items-center text-gray-dark"
                >
                    <i
                        class="material-icons text-gray-dark pr-2"
                        style="font-size: 18px"
                        >arrow_back</i
                    >
                    Rutas
                </router-link>
                <i class="p-5">/</i>
                <router-link to="#" class="text-gray-dark white-space-nowrap">
                    Creaci&oacute;n de ruta
                </router-link>
            </nav>
            <div class="d-flex flex-row flex-wrap w-100 pt-3 pl-1">
                <div class="d-flex flex-column pr-3">
                    <h3
                        class="
                            text-secondary
                            m-0
                            pl-3
                            white-space-nowrap
                            font-large
                        "
                    >
                        Informaci&oacute;n de la ruta
                    </h3>
                    <div class="d-flex flex-column pl-3 pt-10 font-medium">
                        <div class="d-flex flex-column">
                            <span>Fecha</span>
                            <span
                                class="
                                    text-black
                                    font-medium
                                    mt-2
                                    font-weight-bold
                                "
                                >{{
                                    moment(route.date).format("DD MMM YYYY")
                                }}</span
                            >
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Turno</span>
                            <i class="material-icons text-primary mt-2">{{
                                parseInt(route.shift_day)
                                    ? "light_mode"
                                    : "nightlight"
                            }}</i>
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Deliveries</span>
                            <ul
                                class="
                                    list-delivery
                                    text-black
                                    mt-2
                                    list-style-none
                                    p-0
                                "
                            >
                                <li
                                    v-for="d in route.deliveries"
                                    :key="d.id"
                                    class="mt-2"
                                >
                                    {{ d.firstName + " " + d.lastName }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex flex-column pl-3 w-75"
                    style="border-left: 1px solid #c0c4cc"
                >
                    <h3
                        class="
                            text-secondary
                            m-0
                            white-space-nowrap
                            font-large
                            w-100
                        "
                    >
                        Ruta
                    </h3>
                    <div class="d-flex flex-column pt-2 font-medium w-100">
                        <div
                            class="
                                bg-primary
                                p-1
                                w-100
                                d-flex
                                flex-row
                                align-items-center
                            "
                            style="border-radius: 4px"
                        >
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >person</i
                                >
                                Delivery
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                    pl-1
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >location_on</i
                                >
                                Zona
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    font-small
                                    pl-1
                                "
                                style="color: #94abcd"
                            >
                                <i
                                    class="material-icons pr-3"
                                    style="font-size: 17px; color: #94abcd"
                                    >receipt_long</i
                                >
                                &Oacute;rdenes
                            </div>
                        </div>
                        <div class="mt-3 d-flex flex-row">
                            <div class="d-flex flex-column">
                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        justify-around
                                        align-items-center
                                    "
                                >
                                    <div class="d-flex flex-column pr-5">
                                        <span class="font-weight-bold"
                                            >Selecciona una provincia</span
                                        >
                                        <el-select
                                            v-model="route.province_id"
                                            @change="getMunicipalities"
                                            class="mt-3"
                                        >
                                            <el-option
                                                v-for="p in route.provinces"
                                                :key="p.id"
                                                :value="p.id"
                                                :label="p.name"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                    <div
                                        class="
                                            p-5
                                            text-center
                                            d-flex
                                            flex-column
                                            justify-center
                                        "
                                        style="background-color: #ebeef5"
                                    >
                                        <span
                                            class="
                                                font-xx-large
                                                text-center text-secondary
                                                font-weight-bold
                                            "
                                            >0</span
                                        >
                                        <span class="mt-10">Paquetes</span>
                                    </div>
                                </div>

                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        justify-around
                                        align-items-center
                                        mt-5
                                    "
                                >
                                    <div class="d-flex flex-column pr-5">
                                        <span class="font-weight-bold"
                                            >Selecciona un municipio</span
                                        >
                                        <el-select
                                            v-model="route.municipality_id"
                                            @change="getNeighbourhood"
                                            class="mt-3"
                                        >
                                            <el-option
                                                v-for="m in route.municipalities"
                                                :key="m.id"
                                                :value="m.id"
                                                :label="m.name"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                    <div
                                        class="
                                            p-5
                                            text-center
                                            d-flex
                                            flex-column
                                            justify-center
                                        "
                                        style="background-color: #ebeef5"
                                    >
                                        <span
                                            class="
                                                font-xx-large
                                                text-center text-secondary
                                                font-weight-bold
                                            "
                                            >0</span
                                        >
                                        <span class="mt-10">Paquetes</span>
                                    </div>
                                </div>

                                <div
                                    class="
                                        d-flex
                                        flex-row
                                        justify-around
                                        align-items-center
                                        mt-5
                                    "
                                >
                                    <div class="d-flex flex-column pr-5">
                                        <span class="font-weight-bold"
                                            >Selecciona un sector</span
                                        >
                                        <el-select
                                            v-model="route.neighbourhood"
                                            @change="changeNeighbourhood"
                                            class="mt-3"
                                        >
                                            <el-option
                                                v-for="n in route.neighbourhoods"
                                                :key="n.id"
                                                :value="n.id"
                                                :label="n.name"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                    <div
                                        class="
                                            p-5
                                            text-center
                                            d-flex
                                            flex-column
                                            justify-center
                                        "
                                        style="background-color: #ebeef5"
                                    >
                                        <span
                                            class="
                                                font-xx-large
                                                text-center text-secondary
                                                font-weight-bold
                                            "
                                            >0</span
                                        >
                                        <span class="mt-10">Paquetes</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="ml-5"
                                style="
                                    width: 400px;
                                    height: 400px;
                                    border: 1px solid #dcdfe6;
                                "
                            ></div>
                        </div>
                    </div>
                    <div
                        class="
                            d-flex
                            flex-row flex-wrap
                            justify-between
                            text-align-center
                            mt-5
                        "
                    >
                        <router-link to="RouteCreationStep1" class="">
                            <button
                                class="btn bg-white pl-3 pr-3 text-center"
                                style="width: 100px"
                            >
                                Cancelar
                            </button>
                        </router-link>
                        <el-button
                            @click="gotoStep3()"
                            :disabled="this.route.neighbourhood == null"
                            class="pull-end bg-yellow"
                            style="float: right"
                            round
                        >
                            Siguiente
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 bg-primary mt-10" style="height: 20px"></div>
    </div>
</template>

<script>
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import Header from "@/components/dashboard/include/Header";
import _service from "@/services/routeListService";
import moment from "moment";
import { ElButton } from "element-plus";
export default {
    name: "step2",
    components: { Header, leftSideBar, ElButton },
    data() {
        return {
            route: {
                date: null,
                shift_day: null,
                shift_night: null,
                deliveries: [],
                provinces: [],
                province_id: null,
                municipality_id: null,
                neighbourhood: null,
                municipalities: [],
                neighbourhoods: [],
            },
            activeName: "first",
        };
    },
    created() {
        this.moment = moment;
        this.route.date = localStorage.getItem("route_date");
        this.route.shift_day = localStorage.getItem("route_shift_day");
        this.route.shift_night = localStorage.getItem("route_shift_night");
        this.route.deliveries = JSON.parse(
            localStorage.getItem("route_deliveries")
        );

        _service.getProvinces().then((province) => {
            this.route.provinces = province.data;
        });
    },
    methods: {
        getMunicipalities() {
            this.route.municipalities = [];
            this.route.municipality_id = null;

            let data = {
                provinceId: this.route.province_id,
            };
            _service.getMunicipalities(data).then((mucipality) => {
                this.route.municipalities = mucipality.data;
            });

            localStorage.setItem("route_province_id", this.route.province_id);
        },
        gotoStep3() {
            this.$router.push("RouteCreationStep3");
        },
        getNeighbourhood() {
            this.route.neighbourhoods = [];
            this.route.neighbourhood = null;

            let data = {
                municipalityId: this.route.municipality_id,
            };
            _service.getNeighbourhood(data).then((neighbourhood) => {
                this.route.neighbourhoods = neighbourhood.data;
            });

            localStorage.setItem(
                "route_municipality",
                this.route.municipality_id
            );
        },
        changeNeighbourhood() {
            var neighbourhood = this.route.neighbourhoods
                .filter((t) => t.id == this.route.neighbourhood)
                .map((t) => ({ id: t.id, name: t.name }));
            if (neighbourhood.length > 0) {
                localStorage.setItem(
                    "route_neighbourhood",
                    JSON.stringify(neighbourhood[0])
                );
            }
        },
        onTabChanged(name) {
            this.tabRole = name.props.label;
        },
    },
};
</script>

<style scoped>
.list-delivery li,
.list-zone li,
.el-collapse-item__header {
    font-weight: bold !important;
}
</style>
