import { api } from "../core/api";

export default {
    getRoutes(params) {
        return api.get("/ShippingRoute/GetAll", { params: params });
    },
    getProvinces() {
        return api.get("/Lookup/GetProvinces");
    },
    getMunicipalities(params) {
        return api.get("/Lookup/GetMunicipalities", { params: params });
    },
    getNeighbourhood(params) {
        return api.get("/Lookup/GetNeighbourhood", { params: params });
    },
    processRoute(params) {
        return api.post("/ShippingRoute/Process", params);
    },
};
